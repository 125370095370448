import userDataFunctions from "@/helpers/userData.js"

// To be used by reports section as well as influencer insight details page
export default {
  methods: {
    /**
     * Load the profile JSON data from the API into Vuex store
     *
     * @param {String} username
     * @param {String} platform
     * @param {Number} accountId
     */
    async loadProfileData({ username, platform, accountId = null, reportId = null, getRecentReport = false }) {
      // Let everyone know that we're currently loading data for this profile
      window.dispatchEvent(new CustomEvent("isLoading", {
        detail: {
          module: "downloadInfluencerInsight",
          origin: "downloadInfluencerInsight/loadProfileData",
          isLoading: true,
          accountId,
          username,
          platform
        }
      }))

      // dispatch vuex action that makes the network request
      await this.$store.dispatch("influencerInsight/fetchProfile", { platform, username, accountId, reportId, getRecentReport })

      // Once the network request is complete, try getting it from the vuex store
      const userData = this.$store.getters["influencerInsight/findProfile"]({ platform, username, accountId, reportId })

      // Let everyone know that we're done loading data for this profile
      window.dispatchEvent(new CustomEvent("isLoading", {
        detail: {
          module: "downloadInfluencerInsight",
          origin: "downloadInfluencerInsight/loadProfileData",
          isLoading: false,
          accountId,
          username,
          platform
        }
      }))

      // The default error message object
      const errorObject = {
        userData,
        createdAt: null,
        generatedAt: null,
        totalValues: null,
        overviewData: null,
        audienceData: null,
        engagementData: null,
        isViewingOldReport: false,
      }

      // if some sort of data is present
      if (userData) {
        // now keep listening for the download
        window.addEventListener("downloadUserReportStart", (e) => {
          if (accountId === e.detail.accountId && platform === e.detail.platform) {
            this.handleDownloadStart(accountId, platform)
          }
        })

        // now compute the structured data for the view
        if (userData.data.error) {
          return {
            ...errorObject,
            computedErrorMessage: userDataFunctions.computeErrorMessage(userData)
          }
        }
        else {
          return {
            userData,
            totalValues: userDataFunctions.computeTotalValues(userData),
            overviewData: userDataFunctions.computeOverviewData(userData),
            audienceData: userDataFunctions.computeAudienceData(userData),
            engagementData: userDataFunctions.computeEngagementData(userData),
            computedErrorMessage: null,
            createdAt: userData.data.created_at * 1000,
            generatedAt: userData.data.generated_at * 1000,
            isViewingOldReport: userData.data.is_viewing_old_report || false,
          }
        }
      }
      // If no user profile data found in the local store
      else {
        return {
          ...errorObject,
          computedErrorMessage: "No data found for this profile."
        }
      }
    }
  }
}
